import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/dashboard2',
    title: 'DASHBOARD',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/trn-inv-quotation/all-trn-inv-quotations/crud',
    title: 'Quotation Request',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/trn-inv-wo-quotation/all-trn-inv-wo-quotations/crud',
    title: 'WO Quotation Request',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  // {
  //   path: '/',
  //   title: 'SUPPLIER',
  //   moduleName: ['helpdesk-query'],
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
  //   imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
  //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //   submenu: [
  //     {
  //       path: './inv-supplier/all-inv-suppliers',
  //       title: 'List of Suppliers',
  //       moduleName: ['helpdesk-query'],
  //       iconType: 'material-icons-two-tone',
  //       icon: 'cloud_upload',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['All'],
  //       submenu: [],
  //       imgSrc: '',
  //       imgArrowDownSrc: '',
  //       imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     },
  //     // {
  //     //   path: './inventory/trn-inv-non-coded-pur-request/all-trn-inv-non-coded-pur-requests/crud',
  //     //   title: 'Request for NIV Items',
  //     //   moduleName: ['helpdesk-query'],
  //     //   iconType: 'material-icons-two-tone',
  //     //   icon: 'cloud_upload',
  //     //   class: '',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: ['All'],
  //     //   submenu: [],
  //     //   imgSrc: '',
  //     //   imgArrowDownSrc: '',
  //     //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     // },
  //     // {
  //     //   path: './inventory/trn-inv-coded-pur-request/all-trn-inv-coded-pur-requests/approve',
  //     //   title: 'Approve Purchase request for Coded Items',
  //     //   moduleName: ['helpdesk-query'],
  //     //   iconType: 'material-icons-two-tone',
  //     //   icon: 'cloud_upload',
  //     //   class: '',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: ['All'],
  //     //   submenu: [],
  //     //   imgSrc: '',
  //     //   imgArrowDownSrc: '',
  //     //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     // },
  //     // {
  //     //   path: './inventory/trn-inv-non-coded-pur-request/all-trn-inv-non-coded-pur-requests/approve',
  //     //   title: 'Approve request for NIV Items',
  //     //   moduleName: ['helpdesk-query'],
  //     //   iconType: 'material-icons-two-tone',
  //     //   icon: 'cloud_upload',
  //     //   class: '',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: ['All'],
  //     //   submenu: [],
  //     //   imgSrc: '',
  //     //   imgArrowDownSrc: '',
  //     //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     // }
  //   ],
  // }
];
