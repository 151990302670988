import {Page404Component} from './authentication/page404/page404.component';
import {AuthLayoutComponent} from './layout/app-layout/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './layout/app-layout/main-layout/main-layout.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/guard/auth.guard';
import {Role} from './core/models/role';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: '/authentication/signin', pathMatch: 'full'},
      {
        path: 'dashboard',
        // canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'inv-supplier',
        loadChildren: () =>
          import('./mst-inv-supplier/mst-inv-supplier.module').then((m) => m.MstInvSupplierModule),
      },
      {
        path: 'trn-inv-quotation',
        loadChildren: () =>
          import('./trn-inv-quotation/trn-inv-quotation.module').then(
            (m) => m.TrnInvQuotationModule
          ),
      },
      {
        path: 'trn-inv-wo-quotation',
        loadChildren: () =>
          import('./trn-inv-wo-quotation/trn-inv-wo-quotation.module').then(
            (m) => m.TrnInvWoQuotationModule
          ),
      },
      {
        path: 'password',
        loadChildren: () =>
          import('./change-password/change-password.module').then((m) => m.ChangePasswordModule),
      }
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {path: '**', component: Page404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
